import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../../components/Layout'
import Section from '../../../components/Section'
import Hero from '../../../components/Hero'
import ModalVideo from '../../../components/ModalVideo'
import Link from '../../../components/Link'
import BookDemoSection from '../../../components/BookDemoSection'
import {bookADemoLinkSwitch} from '../../../components/lib/bookADemoLinkSwitch'

import '../../platform.scss'

import bannerImg from '../../../images/features2023/admin-and-reporting/Banner_Image/MyHR-Platform-Admin and Reporting Banner@2x.png'
import bookDemoImage from '../../../images/case-study/Sales_Tile_2022-04.png'

import home2022_1 from '../../../images/features2023/admin-and-reporting/Feature_Images/MyHR-Platform-Centralised People Data AU@2x.png'
import home2022_2 from '../../../images/features2023/admin-and-reporting/Feature_Images/MyHR-Platform-Employee Records@2x.png'
import home2022_3 from '../../../images/features2023/admin-and-reporting/Feature_Images/MyHR-Platform-Custom reminders AU@2x.png'
import home2022_4 from '../../../images/features2023/admin-and-reporting/Feature_Images/MyHR-Platform-Workplace insights@2x.png'
import home2022_5 from '../../../images/features2023/admin-and-reporting/Feature_Images/MyHR-Platform-Remuneration Reviews@2x.png'

import otherFeatures1 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-HR Compliance and HR Advisory@2x.png'
import otherFeatures2 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Hiring and Onboarding@2x.png'
import otherFeatures3 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Admin and Reporting@2x.png'
import otherFeatures4 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Leave and Payroll@2x.png'
import otherFeatures5 from '../../../images/features2023/performance-reviews/Other_Features_Icons/PNG/MyHR-Platform-Performance Reviews@2x.png'

import resource1 from '../../../images/features2023/resource-thumbnails/MyHR-Admin-Resources_Common Mistakes when Restructuring@2x.png'
import resource2 from '../../../images/features2023/resource-thumbnails/MyHR_Admin_Resources_Employee Remuneration Download@2x.png'
import resource3 from '../../../images/features2023/resource-thumbnails/MyHR_Admin_Resources_Remuneration Webina@2x.png'

import bannerIcon1 from '../../../images/features2023/admin-and-reporting/Top_Banner_Icons/23-140_MyHR_Admin_Secure-database.png'
import bannerIcon2 from '../../../images/features2023/admin-and-reporting/Top_Banner_Icons/23-140_MyHR_Admin_Data-driven-decisions.png'
import bannerIcon3 from '../../../images/features2023/performance-reviews/Top_Banner_Icons/PNG/MyHR-Platform-Powered by HR Professionals@2x.png'

import stars from '../../../images/home2022/MyHR-Home_Stars@2x.png'
import capterra from '../../../images/home2022/MyHR-Home_Capterra@2x.png'
import google from '../../../images/home2022/MyHR-Home_Google@2x.png'

import customerStoryLogoAU from '../../../images/features/customer_story_logos/HSE2.png'
import imageVidAU from '../../../images/features/customer_thumbnails/MyHR_Features_HSE_thumb.jpg'
const videoAU = {
  url: 'https://www.youtube.com/embed/kYU7cOWOGyM',
  channel: 'youtube',
}

export default () => {
  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  var locale = {}
  locale.value = "en-AU"
  const demoLink = bookADemoLinkSwitch(locale.value)

  const clickCard = (event) => {
    event.preventDefault();
    var el = event.currentTarget;
    window.location.href = el.getAttribute("data-href")
  }

  return (
    <Layout className="PlatformPage" title="Our Platform | Admin, Reporting & Analytics" description="The best human resource solution for advanced reporting, people data analytics, & employee remuneration review. Make HR easy with smart software." locale="en-AU">
      <Hero variant="admin-and-reporting">
        <h1 className="restrict-width">Advanced people data & HR reporting.</h1>
        <p>
          Real-time analytics. <span>Informed decision-making.</span>
        </p>
        <Button
          color="cyan"
          href={demoLink}
          className="mr-3"
        >
          Book a Demo
        </Button>
      </Hero>

      <Section className="BannerIconSection">
        <Container>
          <Row className="">
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon1} />
              <p>A secure database for all your people data</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon2} />
              <p>Make data-driven decisions with robust employee reports</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon3} />
              <p>Expert HR guidance to back you through any process</p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="Content greyBack">
        <Container>

          <Row className="mt-5 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>Centralised people data</h4>
              <h3>Access-controlled database for your sensitive information </h3>
              <p>
                Don't take risks with your important staff and company data. MyHR stores your employee database securely and orderly, 
                so the right people can access what they need, whenever and wherever they need it. No more hunting through cluttered 
                shared drives or piles of paperwork.
              </p>
              <ul>
                <li>2-Factor authentication</li>
                <li>Secure departmental access</li>
                <li>Encrypted file transfer</li>
                <li>Enterprise-level security</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_1} className="" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_2} className="" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>Employee records</h4>
              <h3>All your people data organised, secure and ready to use</h3>
              <p>
                MyHR provides a single view of all your staff data, storing documents and employment records safely in the AWS cloud. 
                Automate everyday tasks and empower your team with easy employee self-service for document signing, performance reviews, 
                leave management and much more.
              </p>
              <ul>
                <li>Employee self-service</li>
                <li>Work visa tracking</li>
                <li>Easy deployment and scalability</li>
              </ul>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>Workplace insights</h4>
              <h3>Back business decisions with data</h3>
              <p>
                Analyse and understand your workplace with meaningful metrics. With all your records at your fingertips and MyHR’s 
                unrivalled access to labour market data, you can easily build deep insight into all your organisational planning and 
                decision-making.
              </p>
              <ul>
                <li>Custom insights and benchmarking</li>
                <li>Org reporting</li>
                <li>Market metrics</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_4} className="" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_3} className="" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>Custom reminders</h4>
              <h3>Never miss key dates or events</h3>
              <p>
                Never miss a key date or event again. Easily set and track reminders with MyHR’s Custom Reminders tool. Be reminded about 
                important events, including the expiry of work visas or trial periods, scheduled employee leave, upcoming performance 
                reviews, and much more.
              </p>
              <ul>
                <li>Custom training and licence tracking</li>
                <li>Smart notes</li>
                <li>Document acceptance</li>
              </ul>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>Remuneration reviews</h4>
              <h3>Get pay right, every time</h3>
              <p>
                Get the right result for every pay review and rest assured your people are getting the best rewards for their performance. 
                MyHR’s specialist insight and extensive client base allow a thorough view of the labour market, so your compensation packages 
                are always accurately assessed and benchmarked.
              </p>
              <ul>
                <li>Market benchmarking</li>
                <li>Remuneration strategy</li>
                <li>Expert oversight</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_5} className="" />
            </Col>
          </Row>

        </Container>
      </Section>

      <Section className="BannerSection py-5 justify-content-center d-flex flex-column align-items-center">
        <Container>
          <Row className="my-5">
            <Col
              md={{ size: 12, offset: 0 }}
              lg={{ size: 6, offset: 0 }}
            >
              <div className="h-100 d-flex align-items-start flex-column justify-content-center">
                <div className="customerStoriesDiv">
                  <div className="d-block d-md-none my-3">
                    <ModalVideo {...videoAU}>
                      {({ toggle }) => (
                        <div
                          className="UntangleHrSection__video"
                          onClick={toggle}
                        >
                          <img src={imageVidAU} alt={'Video Thumbnail'} />
                          <div className="UntangleHrSection__video-play-icon">
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="icon play-icon"
                            />
                          </div>
                        </div>
                      )}
                    </ModalVideo>
                  </div>
                  <h3 className="my-4 pr-0 pr-md-6 quoteText">
                    “The hands-on HR support has been incredibly beneficial. They’ve helped us through a lot of sticky situations.”
                  </h3>
                  <div className="customerStoryBy">
                    <p>
                      <span>Amanda Tait, HR Coordinator, HSE</span>
                    </p>
                    <img
                      src={customerStoryLogoAU}
                      className="customerStoryLogo"
                    alt="Logo" />
                  </div>
                  <Link
                    type="button"
                    color="radical-red"
                    href="/customer-stories"
                    className="customerStoriesBtn"
                    localePrefix={true}
                  >
                    More customer stories
                  </Link>
                  <p>&nbsp;</p>
                </div>
              </div>
            </Col>
            <Col
              className="d-none d-md-block"
              md={{ size: 12 }}
              lg={{ size: 6 }}
            >
              <ModalVideo {...videoAU}>
                {({ toggle }) => (
                  <div className="UntangleHrSection__video" onClick={toggle}>
                    <img src={imageVidAU} alt={'Video Thumbnail'} />
                    <div className="UntangleHrSection__video-play-icon">
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="icon play-icon"
                      />
                    </div>
                  </div>
                )}
              </ModalVideo>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="OtherFeaturesSection Content">
        <h2 className="text-center">Check out our other features</h2>
        <Container>
          <Row className="mt-6">
            <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/hr-compliance-and-advisory">
                <img src={otherFeatures1} />
                <h3>HR Compliance & <span>HR Advisory</span></h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/hr-compliance-and-advisory"
                  className="mr-3"
                >
                  Compliance features
                </Button>
                <hr />
                <ul>
                  <li>Cloud-based contracts</li>
                  <li>HR documents on demand</li>
                  <li>HR advice & platform support</li>
                  <li>100% paperless</li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/hiring-and-onboarding">
                <img src={otherFeatures2} />
                <h3>Hiring & Onboarding</h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/hiring-and-onboarding"
                  className="mr-3"
                >
                  Onboarding features
                </Button>
                <hr />
                <ul>
                  <li>E-sign contracts</li>
                  <li>Paperless onboarding</li>
                  <li>Recruitment support</li>
                  <li>Employment agreements</li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/performance-review-development">
                <img src={otherFeatures5} />
                <h3>Performance & Development</h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/performance-review-development"
                  className="mr-3"
                >
                  Performance features
                </Button>
                <hr />
                <ul>
                  <li>Paperless performance reviews</li>
                  <li>Automated smart notes</li>
                  <li>Employee development</li>
                  <li>Integrated advisory & support</li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} className="">
              <div className="card d-flex flex-column justify-content-center" onClick={clickCard} data-href="/au/platform/leave-and-payroll">
                <img src={otherFeatures4} />
                <h3>Leave & <span className="opposite">Payroll</span></h3>
                <Button
                  color="electric-blue"
                  href="/au/platform/leave-and-payroll"
                  className="mr-3"
                >
                  Leave & payroll features
                </Button>
                <hr />
                <ul>
                  <li>MyHR Payroll</li>
                  <li>Payroll integrations </li>
                  <li>Leave management </li>
                  <li>Sync employee records</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>      

      <Section className="ResourceSection">
        <h3 className="text-center">HR Resource Bundle</h3>
        <Container>
          <Row className="mt-6">
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100" onClick={clickCard} data-href="https://blog.myhr.works/en-au/common-restructuring-mistakes">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faBookOpen} /> Blog</p>
                    <h3>Common mistakes when restructuring</h3>
                  </div>
                  <img src={resource1} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>A guide to common restructuring and redundancy errors so you can avoid them.</p>
                  <a className="sectionLinkArrow" href="https://blog.myhr.works/en-au/common-restructuring-mistakes">Read the blog&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100" onClick={clickCard} data-href="https://info.myhr.works/en-au/how-to-get-employee-remuneration-right">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faDownload} /> Free Download</p>
                    <h3>How to get employee remuneration right</h3>
                  </div>
                  <img src={resource2} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>The importance of remuneration and how to have constructive pay conversations.</p>
                  <a className="sectionLinkArrow" href="https://info.myhr.works/en-au/how-to-get-employee-remuneration-right">Download the free guide&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} className="">
              <div className="card d-flex flex-column justify-content-between h-100" onClick={clickCard} data-href="https://www.youtube.com/watch?v=YL9n7x9De-o">
                <div>
                  <div>
                    <p><FontAwesomeIcon icon={faPlay} /> Webinar #37</p>
                    <h3>Remuneration, pay increases, and how to manage pay conversations</h3>
                  </div>
                  <img src={resource3} />
                </div>
                <div className="d-flex flex-column">
                  <hr />
                  <p>With Sylvie Thrush Marsh and Julian Hackenberg.</p>
                  <a className="sectionLinkArrow" href="https://www.youtube.com/watch?v=YL9n7x9De-o">Watch the webinar&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <BookDemoSection/>

      <Section className="FaqSectionOld">
        <Container>
          <h2 className="mb-5">Admin and HR reporting FAQs</h2>
          <ul>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>What is HR reporting?</h3>
              <p>
                HR reporting tracks key information and metrics about your people and their performance. Reporting provides valuable 
                insight into your workforce and organisation, ensuring the business can make accurate, data-driven decisions on 
                staffing, processes, and company goals.
              </p>
            </li>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>How does automating HR tasks make life easier for employers & managers?</h3>
              <p>
                Automating HR functions and tasks saves time, reduces costs, increases the accuracy of delivery, and improves compliance 
                with employment law and other legislation. Once basic people processes are automated, employers and managers can focus 
                on work that adds value to the business.
                <br /><br />
                <a href="https://blog.myhr.works/en-au/outsource-hr-team-and-tech">Read more automating HR tips</a>
              </p>
            </li>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>How do I use market data and employee insights to help my business be more effective?</h3>
              <p>
                Using data and insights leads to better decision-making and outcomes, because they are based on evidence. Using market 
                salary data will help ensure your people are well remunerated, which drives employee engagement and retention. Insights 
                from your team will mean you better understand the realities of the business, what is working and what could be improved, 
                which leads to a better experience for your employees and customers.
              </p>
            </li>
            <li>
              <input type="checkbox" defaultChecked />
              <i></i>
              <h3>What is an effective remuneration package for employee retention?</h3>
              <p>
                There are many aspects to remuneration and to retain employees, you need to ensure the parts form an attractive package 
                for each person. Money is important, but you should also look at what you can offer in terms of benefits, training and 
                  development, company culture, flexible work options, and meaningful work. Also, make sure you regularly review employees’ 
                remuneration, so it keeps up with expectations. 
                <br /><br />
                <a href="https://blog.myhr.works/en-au/how-to-effectively-find-and-retain-employees">Read more remuneration tips</a>
              </p>
            </li>
          </ul>
        </Container>
      </Section>

    </Layout>
  )
}
